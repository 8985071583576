<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_company") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createCompany"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const fivaldiCustomers = computed(
      () => store.state.company.fivaldiCustomers
    );

    const waiting = computed(() => store.state.company.waiting);

    const formElements = ref();

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: "",
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "datepicker",
          id: "licence-date",
          label: "licence_validity",
          rules: ["isRequired"],
          textVariant: "white",
          value: getISODate(new Date()),
          variable: "licenceValidity",
          variant: "indigo",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "email",
          label: "email",
          rules: ["isRequired", "isEmail"],
          type: "text",
          value: "",
          variable: "email",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "phone",
          label: "phone",
          rules: ["isRequired", "isPhoneNumber"],
          type: "text",
          value: "",
          variable: "phone",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "fivaldi_customer_select",
          label: "fivaldi_customer",
          optionLabel: ["label"],
          options: fivaldiCustomers.value,
          rules: [],
          value: null,
          variable: "fivaldiId",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: "white",
          type: "submit",
          variant: "indigo",
        },
      ];
    };

    const createCompany = (data) => {
      data.primary = {
        50: "#eef2ff",
        100: "#e0e7ff",
        200: "#c7d2fe",
        300: "#a5b4fc",
        400: "#818cf8",
        500: "#6366f1",
        600: "#4f46e5",
        700: "#4338ca",
        800: "#3730a3",
        900: "#312e81",
      };
      data.primaryText = "light";
      store.dispatch("company/createCompany", data);
    };

    onMounted(() => {
      if (!fivaldiCustomers.value || !fivaldiCustomers.value.length) {
        store.dispatch("company/getFivaldiCustomers");
      } else {
        setFormElements();
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      createCompany,
      formElements,
      t,
    };
  },
};
</script>
